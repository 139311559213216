<template>
  <div v-if="init" class="chat-wrapper">
    <ChatLightbox />

    <!-- Admin selector (Top) -->
    <ChatAdminSelector />

    <div class="chat-new-main">
      <ChatRoomSelector />

      <ChatWindow v-if="selectedChatRoom !== false" />
      <div v-else style="width: 800px; max-width: 100%"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
// let audio = new Audio(require('@/assets/message.mp3'))

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Chat",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    simplebar,
  },
  computed: {
    ...mapState({
      userId: (state) => state.settings.user.id,
      selectedChatAdmin: (state) => state.chat.selectedChatAdmin,
      selectedChatRoom: (state) => state.chat.selectedChatRoom,
      chatRoomMessages: (state) => state.chat.chatRoomMessages,
      notifications: (state) => state.chat.notifications,
      init: (state) => state.settings.init,
      appBrand: (state) => state.settings.appSettings.app,
    }),
    ...mapGetters("chat", [
      "selectedAdminPersonalChatRooms",
      "selectedAdminCompanyChatRooms",
      "totalNotificationCount",
      "notificationCountByAdmin",
      "adminUsersWithChatRooms",
      "selectedChatRoomName",
    ]),
    ...mapGetters("chat", ["sortedFilteredChatRooms"]),
    ...mapGetters("users", ["getUserNameById", "getUserCompanyById"]),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("companies", ["getCompanyNameByVismaId"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
label[for="file"] {
  cursor: pointer !important;
  z-index: -1 !important;
}
</style>
